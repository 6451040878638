import {useCallback, useEffect, useState} from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import {Flex, Loader} from '@mantine/core';
import {Dealer, Home, Infrastructure, NotFound} from './pages';
import ApiClient from './utils/ApiClient';
import useStyles from './styles';
import useAppStore from './store';

const App = () => {
  const {classes} = useStyles();
  const [initializing, setInitializing] = useState(true);
  const setDealer = useAppStore(state => state.setDealer);

  const getMe = useCallback(async () => {
    try {
      const {data} = await ApiClient.me();
      setDealer(data);
      setInitializing(false);
    } catch (e) {
      console.log(e);
      localStorage.removeItem('token');
    }
  }, [setDealer]);

  useEffect(() => {
    (async () => {
      await getMe();
      setInitializing(false);
    })();
  }, [getMe]);

  return (
    <>
      {initializing ? (
        <Flex align="center" justify="center" className={classes.preloader}>
          <Loader/>
        </Flex>
      ) : (
        <HashRouter future={{v7_startTransition: true}}>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/altyapi-sorgulama" element={<Infrastructure/>}/>
            <Route path="/bayi" element={<Dealer/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </HashRouter>
      )}
    </>
  );
}

export default App;
