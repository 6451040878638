import {create} from 'zustand'
import createSessionSlice, {SessionSliceType} from "./createSessionSlice";
import createDealerSlice, {DealerSliceType} from "./createDealerSlice";

export type AppSliceType = SessionSliceType & DealerSliceType;

const useAppStore = create<AppSliceType>()((...a) => ({
  ...createSessionSlice(...a),
  ...createDealerSlice(...a),
}))

export default useAppStore;
