import React, {ReactNode} from "react";
import {Container} from "@mantine/core";
import useStyles from "../../styles";
import {Footer} from "./index";

type LayoutProps = {
  children: ReactNode;
}

const Layout = ({children}: LayoutProps) => {
  const {classes} = useStyles();

  return (
    <Container className={classes.container}>
      {children}
      <Footer className={classes.footer}/>
    </Container>
  );
}

export default Layout;
