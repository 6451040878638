import {useCallback, useEffect, useState} from 'react';
import {Box, Button, createStyles, Flex, Grid, Loader, LoadingOverlay, PasswordInput, TextInput} from '@mantine/core';
import {useForm, yupResolver} from '@mantine/form';
import {notifications} from '@mantine/notifications';
import {useFocusTrap} from '@mantine/hooks';
import {IconArrowRight, IconPassword, IconUser, IconX} from '@tabler/icons-react';
import Yup from '../../utils/yup';
import ApiClient from '../../utils/ApiClient';
import useAppStore from '../../store';

const useStyles = createStyles(theme => ({
  box: {
    margin: 'auto',
    padding: theme.spacing.md,
    maxWidth: 340
  },
  form: {
    position: 'relative'
  }
}));

const DealerLoginForm = () => {
  const {classes} = useStyles();
  const focusTrapRef = useFocusTrap();
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const setDealer = useAppStore(state => state.setDealer);

  const form = useForm({
    validate: yupResolver(Yup.object().shape({
      username: Yup.string()
        .nullable()
        .required('Kullanıcı adı girmelisiniz'),
      password: Yup.string()
        .nullable()
        .required('Şifre girmelisiniz')
    }))
  });

  const getMe = useCallback(async () => {
    try {
      const {data} = await ApiClient.me();
      setDealer(data);
    } catch (e) {
      console.log(e);
      localStorage.removeItem('token');
    }
  }, [setDealer]);

  useEffect(() => {
    (async () => {
      await getMe();
      setInitializing(false);
    })();
  }, [getMe]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const {data} = await ApiClient.login(values.username, values.password);
      localStorage.setItem('token', data.token);
      await getMe();
    } catch (e) {
      console.log(e);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        title: "Hata!",
        message: 'Bilgileriniz hatalı!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      })
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box className={classes.box}>
      {initializing ? (
        <Flex align="center" justify="center">
          <Loader variant="bars"/>
        </Flex>
      ) : (
        <form ref={focusTrapRef} className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
          <LoadingOverlay visible={loading} overlayBlur={2}/>
          <Grid>
            <Grid.Col span={12}>
              <TextInput label="Kullanıcı adı"
                         placeholder="Kullanıcı adı giriniz"
                         icon={<IconUser size="1rem"/>}
                         withAsterisk
                         autoFocus
                         data-autofocus
                         {...form.getInputProps('username')}/>
            </Grid.Col>
            <Grid.Col span={12}>
              <PasswordInput label="Şifre"
                             placeholder="Şifre giriniz"
                             icon={<IconPassword size="1rem"/>}
                             withAsterisk
                             {...form.getInputProps('password')}/>
            </Grid.Col>
            <Grid.Col span={12} className="text-right">
              <Button type="submit"
                      rightIcon={<IconArrowRight size={14}/>}
                      fullWidth
                      loading={loading}
                      disabled={loading}>Giriş</Button>
            </Grid.Col>
          </Grid>
        </form>
      )}
    </Box>
  )
    ;
}

export default DealerLoginForm;
