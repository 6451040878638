import {StateCreator} from 'zustand';
import {AppSliceType} from './';

export type SessionType = {
  id: string;
} | null

export type SessionSliceType = {
  session: SessionType;
  setSession: (session: SessionType) => void;
}

const createSessionSlice: StateCreator<AppSliceType, [], [], SessionSliceType> = (set) => ({
  session: null,
  setSession: (session) => set({session}),
})

export default createSessionSlice;
