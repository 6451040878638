import {Link} from "react-router-dom";
import {Button, Image} from "@mantine/core";
import {IconArrowLeft} from "@tabler/icons-react";
import useStyles from "../styles";
import {Layout} from "../components";
import logo from '../assets/img/logo.png';

const NotFound = () => {
  const {classes} = useStyles();

  return (
    <Layout>
      <div className={classes.notFound}>
        <Image src={logo} alt="logo" m="auto" width="281"/>
        <p>(404) Aradığınız sayfa bulunamadı!</p>
        <Button leftIcon={<IconArrowLeft/>} color="green" component={Link} to="/">Başlangıca Dön</Button>
      </div>
    </Layout>
  );
}

export default NotFound;
