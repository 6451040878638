import {createStyles} from "@mantine/core";

const useStyles = createStyles(theme => ({
  preloader: {
    width: '100%',
    height: '100vh'
  },
  container: {
    padding: theme.spacing.md,
    maxWidth: 960
  },
  header: {
    marginBottom: theme.spacing.md,
    '> h1': {
      fontSize: theme.fontSizes.xl,
      textAlign: 'center'
    }
  },
  start: {
    position: 'relative',
    marginBottom: theme.spacing.md
  },
  infrastructure: {
    marginBottom: theme.spacing.md,
    '> h1': {
      fontSize: theme.fontSizes.xl,
      textAlign: 'center'
    },
    '.headline': {
      marginBottom: theme.spacing.md,
      fontSize: theme.fontSizes.md,
      textAlign: 'center'
    },
    '.tabs': {
      width: '100%',
      '.tabs-list': {
        marginBottom: theme.spacing.md,
      }
    },
    'form': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      width: '100%',
      maxWidth: 600,
      minHeight: 100,
      '.container': {
        width: '100%'
      }
    },
    '.results': {
      '> h1': {
        margin: 0,
        padding: theme.spacing.md,
        width: '100%',
        backgroundColor: theme.colors.teal[1],
        borderRadius: theme.radius.sm,
        fontSize: theme.fontSizes.xl,
        textAlign: 'center'
      },
      '> .item': {
        margin: 0,
        padding: 0,
        width: '100%',
        textAlign: 'center',
        '> h2': {
          margin: 0,
          padding: theme.spacing.sm,
          width: '100%',
          borderRadius: theme.radius.sm,
          fontSize: theme.fontSizes.md,
          textAlign: 'center'
        },
      }
    }
  },
  notFound: {
    textAlign: 'center',
    fontSize: theme.fontSizes.xl,
    marginTop: theme.spacing.xl
  },
  footer: {
    marginTop: theme.spacing.md,
    textAlign: 'center',
    fontSize: theme.fontSizes.sm
  }
}));

export default useStyles;
