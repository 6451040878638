import {useRef, useState, KeyboardEvent} from 'react';
import {Alert, Box, createStyles, Grid, Input, LoadingOverlay} from '@mantine/core';
import {useForm, yupResolver} from '@mantine/form';
import {useFocusTrap} from '@mantine/hooks';
import {IconCodeAsterix, IconInfoCircle} from '@tabler/icons-react';
import Yup from '../../utils/yup';
import {IMaskInput} from 'react-imask';
import apiClient from '../../utils/ApiClient';

type PropsType = {
  oturumId: string;
  telefon: string;
  onVerified: () => void;
}

const useStyles = createStyles(theme => ({
  box: {
    margin: 'auto',
    maxWidth: 420
  }
}));

const GsmCheckForm = ({oturumId, telefon, onVerified}: PropsType) => {
  const {classes} = useStyles();
  const focusTrapRef = useFocusTrap();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    validate: yupResolver(Yup.object().shape({
      kod: Yup.string()
        .nullable()
        .required('Kod girmelisiniz')
        .min(6, 'Kod en az 6 karakter olmalıdır')
    })),
    initialValues: {},
  });

  const handleSubmit = async (kod: string) => {
    try {
      setLoading(true);
      await apiClient.cepTelefonuKodKontrol(oturumId, telefon, kod);
      form.setFieldValue('kod', '');
      onVerified();
    } catch (e) {
      console.log(e);
      form.setFieldError('kod', 'Kod geçersiz');
      setTimeout(() => {
        const el = document.getElementById('input-kod') as HTMLInputElement
        if (el) {
          el.focus();
          el.select();
        }
      }, 200);
    } finally {
      setLoading(false);
    }
  }

  const handleTypeCode = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (!(form.getInputProps('kod').value && form.getInputProps('kod').value.length === 6)) {
      return e;
    }

    await handleSubmit(form.getInputProps('kod').value);
  }

  return (
    <Box className={classes.box}>
      <Alert icon={<IconInfoCircle size="1rem"/>} color="gray" mb="md">
        <strong>{telefon}</strong> numaralı cep telefonuza doğrulamak için gönderilen 6 haneli kodu giriniz.
      </Alert>
      <form ref={focusTrapRef}>
        <LoadingOverlay visible={loading} overlayBlur={2}/>
        <Grid>
          <Grid.Col span={12}>
            <Input.Wrapper label="Kod"
                           withAsterisk
                           {...form.getInputProps('kod')}
                           ref={inputRef}>
              <Input placeholder="Kod giriniz"
                     icon={<IconCodeAsterix size="1.5rem"/>}
                     id="input-kod"
                     component={IMaskInput}
                     mask="000000"
                     size="xl"
                     disabled={loading}
                     {...form.getInputProps('kod')}
                     onKeyUp={handleTypeCode}/>
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
}

export default GsmCheckForm;
