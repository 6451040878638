import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {DealerLoginForm, Header, Layout} from '../components';
import useAppStore from '../store';
import useStyles from '../styles';

const Dealer = () => {
  const {classes} = useStyles();
  const navigate = useNavigate();
  const dealer = useAppStore(state => state.dealer);

  useEffect(() => {
    if (dealer) {
      navigate('/');
    }
  }, [dealer, navigate]);

  return (
    <Layout>
      <Header title="Bayi Girişi" className={classes.header}/>
      {!dealer && <DealerLoginForm/>}
    </Layout>
  )
}

export default Dealer;
