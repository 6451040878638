import {useEffect, useRef, useState} from "react";
import {
  Alert,
  Badge,
  Button,
  Divider,
  Flex,
  Loader,
  LoadingOverlay,
  Select,
  Tabs,
  Text, TextInput
} from "@mantine/core";
import {useForm, yupResolver} from '@mantine/form';
import {notifications} from "@mantine/notifications";
import {
  IconCodeAsterix,
  IconInfoCircle,
  IconListCheck,
  IconRefresh,
  IconSend,
  IconX
} from "@tabler/icons-react";
import ApiClient from "../utils/ApiClient";
import {SelectOptionType} from "../types";
import {Layout} from "../components";
import useStyles from "../styles";
import Yup from "../utils/yup";
import {Link} from "react-router-dom";

type ServiceStatusType = {
  type: 'ADSL' | 'VDSL' | 'Fiber' | null;
  service: boolean;
  port: boolean;
  speed: number;
  distance?: number;
}

const Infrastructure = () => {
  const {classes} = useStyles();

  const [initialized, setInitialized] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [queryType, setQueryType] = useState<'address' | 'code'>('address');

  const [sehirler, setSehirler] = useState<SelectOptionType[]>([]);
  const [selectedSehir, setSelectedSehir] = useState<SelectOptionType | null>();
  const [ilceler, setIlceler] = useState<SelectOptionType[]>([]);
  const [selectedIlce, setSelectedIlce] = useState<SelectOptionType | null>();
  const [koyler, setKoyler] = useState<SelectOptionType[]>([]);
  const [selectedKoy, setSelectedKoy] = useState<SelectOptionType | null>();
  const [bucaklar, setBucaklar] = useState<SelectOptionType[]>([]);
  const [selectedBucak, setSelectedBucak] = useState<SelectOptionType | null>();
  const [mahalleler, setMahalleler] = useState<SelectOptionType[]>([]);
  const [selectedMahalle, setSelectedMahalle] = useState<SelectOptionType | null>();
  const [sokaklar, setSokaklar] = useState<SelectOptionType[]>([]);
  const [selectedSokak, setSelectedSokak] = useState<SelectOptionType | null>();
  const [binalar, setBinalar] = useState<SelectOptionType[]>([]);
  const [selectedBina, setSelectedBina] = useState<SelectOptionType | null>();
  const [daireler, setDaireler] = useState<SelectOptionType[]>([]);
  const [selectedDaire, setSelectedDaire] = useState<SelectOptionType | null>();
  const [adres, setAdres] = useState<string | null>();
  const [bbkKod, setBbkKod] = useState<string | null>();
  const [serviceStatus, setServiceStatus] = useState<ServiceStatusType | null>(null);

  const ilceRef = useRef<HTMLInputElement | null>(null);
  const bucakRef = useRef<HTMLInputElement | null>(null);
  const koyRef = useRef<HTMLInputElement | null>(null);
  const mahalleRef = useRef<HTMLInputElement | null>(null);
  const sokakRef = useRef<HTMLInputElement | null>(null);
  const binaRef = useRef<HTMLInputElement | null>(null);
  const daireRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const form = useForm({
    validate: yupResolver(Yup.object().shape({
      bbk: Yup.string()
        .test('required', 'BBK Kodu giriniz', value => {
          if (queryType !== 'code') return true;
          return queryType === 'code' && value !== '';
        }),
    })),
    initialValues: {
      bbk: '',
    }
  });

  const getInitialData = async () => {
    try {
      setInitialized(false);
      setInitialError(false);
      const {data} = await ApiClient.sehirler();
      const sehirlerList: SelectOptionType[] = [];
      data.map((sehir: any) => sehirlerList.find((item: SelectOptionType) =>
        item.value === sehir.kod) || (sehirlerList.push({value: sehir.value, label: sehir.text})));
      setSehirler(sehirlerList);
      setInitialized(true);
    } catch (e: any) {
      console.log(e, e.response);
      setInitialError(true);
    }
  }

  useEffect(() => {
    (async () => {
      await getInitialData();
    })();
  }, []);

  const handleChangeSehir = async (value: string) => {
    setSelectedSehir(sehirler.find(item => item.value === value));
    setKoyler([]);
    setBucaklar([]);
    setBinalar([]);
    setDaireler([]);
    setSokaklar([]);
    setMahalleler([]);
    setIlceler([]);
    setSelectedIlce(null);
    setSelectedBucak(null);
    setSelectedKoy(null);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    form.setFieldValue('sehir', value);
    form.setFieldValue('ilce', null);
    form.setFieldValue('bucak', null);
    form.setFieldValue('koy', null);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value === null) {
      setIlceler([]);
      return;
    }

    try {
      setLoading(true);
      const {data} = await ApiClient.ilceler(value);
      const ilcelerList: SelectOptionType[] = [];
      data.map((ilce: any) => ilcelerList.find((item: SelectOptionType) =>
        item.value === ilce.kod) || (ilcelerList.push({value: ilce.value, label: ilce.text})));
      setIlceler(ilcelerList);
      setTimeout(() => {
        ilceRef.current?.focus();
      }, 200);
    } catch (e: any) {
      setSelectedIlce(null);
      setIlceler([]);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      form.setFieldValue('ilce', null);
      setLoading(false);
    }
  }

  const handleChangeIlce = async (value: string) => {
    setSelectedIlce(ilceler.find(item => item.value === value));
    setBucaklar([]);
    setKoyler([]);
    setMahalleler([]);
    setSokaklar([]);
    setBinalar([]);
    setDaireler([]);
    setSelectedBucak(null);
    setSelectedKoy(null);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    form.setFieldValue('ilce', value);
    form.setFieldValue('bucak', null);
    form.setFieldValue('koy', null);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setLoading(true);
        const {data} = await ApiClient.bucaklar(value);
        const bucaklarList: SelectOptionType[] = [];
        data.map((bucak: any) => bucaklarList.find((item: SelectOptionType) =>
          item.value === bucak.kod) || (bucaklarList.push({value: bucak.value, label: bucak.text})));
        if (bucaklarList.length === 1) {
          await handleChangeBucak(bucaklarList[0].value);
          form.setFieldValue('bucak', bucaklarList[0].value);
          setSelectedBucak(bucaklarList[0]);
          setTimeout(() => {
            koyRef.current?.focus();
          }, 200);
        } else {
          form.setFieldValue('bucak', null);
          setTimeout(() => {
            bucakRef.current?.focus();
          }, 200);
        }
        setBucaklar(bucaklarList);
      } catch (e: any) {
        setSelectedBucak(null);
        setBucaklar([]);
        form.setFieldValue('bucak', null);
        notifications.show({
          withCloseButton: true,
          autoClose: 2000,
          message: e.response.data.error ?? 'Bir hata oluştu!',
          color: 'red',
          icon: <IconX/>,
          loading: false,
        });
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleChangeBucak = async (value: string) => {
    setSelectedBucak(bucaklar.find(item => item.value === value));
    setKoyler([]);
    setMahalleler([]);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    form.setFieldValue('bucak', value);
    form.setFieldValue('koy', null);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value === null) {
      setKoyler([]);
      return;
    }

    try {
      setLoading(true);
      const {data} = await ApiClient.koyler(value);
      const koylerList: SelectOptionType[] = [];
      data.map((koy: any) => koylerList.find((item: SelectOptionType) =>
        item.value === koy.value) || (koylerList.push({value: koy.value, label: koy.text})));
      if (koylerList.length === 1) {
        await handleChangeKoy(koylerList[0].value);
        form.setFieldValue('koy', koylerList[0].value);
        setSelectedKoy(koylerList[0]);
        setTimeout(() => {
          mahalleRef.current?.focus();
        }, 200);
      } else {
        setTimeout(() => {
          koyRef.current?.focus();
        }, 200);
      }
      setKoyler(koylerList);
    } catch (e: any) {
      form.setFieldValue('koy', null);
      setSelectedKoy(null);
      setKoyler([]);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const handleChangeKoy = async (value: string) => {
    setSelectedKoy(koyler.find(item => item.value === value));
    setMahalleler([]);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    form.setFieldValue('koy', value);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value === null) {
      setMahalleler([]);
      return;
    }

    try {
      setLoading(true);
      const {data} = await ApiClient.mahalleler(value);
      const mahallelerList: SelectOptionType[] = [];
      data.map((mahalle: any) => mahallelerList.find((item: SelectOptionType) =>
        item.value === mahalle.kod) || (mahallelerList.push({value: mahalle.value, label: mahalle.text})));
      setMahalleler(mahallelerList);
      setTimeout(() => {
        mahalleRef.current?.focus();
      }, 200);
    } catch (e: any) {
      setSelectedMahalle(null);
      setMahalleler([]);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      form.setFieldValue('mahalle', null);
      setLoading(false);
    }
  }

  const handleChangeMahalle = async (value: string) => {
    setSelectedMahalle(mahalleler.find(item => item.value === value));
    setSokaklar([]);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    form.setFieldValue('mahalle', value);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value === null) {
      setSokaklar([]);
      return;
    }

    try {
      setLoading(true);
      const {data} = await ApiClient.sokaklar(value);
      const sokaklarList: SelectOptionType[] = [];
      data.map((sokak: any) => sokaklarList.find((item: SelectOptionType) =>
        item.value === sokak.kod) || (sokaklarList.push({value: sokak.id, label: sokak.text})));
      setSokaklar(sokaklarList);
      setTimeout(() => {
        sokakRef.current?.focus();
      }, 200);
    } catch (e: any) {
      setSelectedSokak(null);
      setSokaklar([]);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      form.setFieldValue('sokak', null);
      setLoading(false);
    }
  }

  const handleChangeSokak = async (value: string) => {
    setSelectedSokak(sokaklar.find(item => item.value === value));
    setBinalar([]);
    setSelectedBina(null);
    setSelectedDaire(null);
    form.setFieldValue('sokak', value);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value === null) {
      setBinalar([]);
      return;
    }

    try {
      setLoading(true);
      const {data} = await ApiClient.binalar(value);
      const binalarList: SelectOptionType[] = [];

      if (data.length === 0) {
        notifications.show({
          withCloseButton: true,
          autoClose: 2000,
          message: 'Bina listesi boş!',
          color: 'red',
          icon: <IconX/>,
          loading: false,
        });
      }

      data.map((bina: any) => binalarList.find((item: SelectOptionType) =>
        item.value === bina.kod) || (binalarList.push({value: bina.id, label: bina.text})));
      setBinalar(binalarList);
      setTimeout(() => {
        binaRef.current?.focus();
      }, 200);
    } catch (e: any) {
      setSelectedBina(null);
      setBinalar([]);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      form.setFieldValue('bina', null);
      setLoading(false);
    }
  }

  const handleChangeBina = async (value: string) => {
    setSelectedBina(binalar.find(item => item.value === value));
    setDaireler([]);
    setSelectedDaire(null);
    form.setFieldValue('bina', value);
    form.setFieldValue('daire', null);

    if (value === null) {
      setDaireler([]);
      return;
    }

    try {
      setLoading(true);
      const {data} = await ApiClient.daireler(value);
      const dairelerList: SelectOptionType[] = [];

      if (data.length === 0) {
        notifications.show({
          withCloseButton: true,
          autoClose: 2000,
          message: 'Daire listesi boş!',
          color: 'red',
          icon: <IconX/>,
          loading: false,
        });
      }

      if (data.length === 0) {
        dairelerList.push({value: value, label: 'Boş'});
      } else {
        if (Array.isArray(data)) {
          data.map((daire: any) => dairelerList.find((item: SelectOptionType) =>
            item.value === daire.kod) || (dairelerList.push({value: daire.bbk, label: daire.door_number})));
        } else {
          dairelerList.push({value: data.self.bbk, label: data.self.door_number});
        }
      }
      setDaireler(dairelerList);
      setTimeout(() => {
        daireRef.current?.focus();
      }, 200);
    } catch (e: any) {
      setSelectedDaire(null);
      setDaireler([]);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      form.setFieldValue('daire', null);
      setLoading(false);
    }
  }

  const handleChangeDaire = async (value: string) => {
    setSelectedDaire(daireler.find(item => item.value === value));
    form.setFieldValue('daire', value);
    setBbkKod(null);
    setAdres(null);
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 200);
  }

  const handleSubmit = async () => {
    let code: string | null = null;
    if (queryType === 'address') {
      if (!selectedDaire) {
        return;
      }
      code = selectedDaire.value
    } else {
      code = form.values.bbk;
    }

    try {
      setLoading(true);
      setBbkKod(null);
      setAdres(null);

      const adresKoduResponse = await ApiClient.adresKodu(code);
      setAdres(adresKoduResponse.data.adres_metin);
      setBbkKod(adresKoduResponse.data.bbk);

      const controlService = adresKoduResponse.data['control_service'];
      let initService: boolean = false;

      if (controlService['adsl'].find !== undefined) {
        const service = controlService['adsl'].find((i: any) => i.name === "SNTRLHZMT") === undefined ?
          0 : controlService['adsl'].find((i: any) => i.name === "SNTRLHZMT")['value'];
        const port = controlService['adsl'].find((i: any) => i.name === "BSPRT") === undefined ?
          0 : controlService['adsl'].find((i: any) => i.name === "BSPRT")['value']
        const speed = controlService['adsl'].find((i: any) => i.name === "NMSMAX") === undefined ?
          0 : parseInt(controlService['adsl'].find((i: any) => i.name === "NMSMAX")['value']);
        const distance = controlService['adsl'].find((i: any) => i.name === "SNTRLMSF") === undefined ?
          0 : parseInt(controlService['adsl'].find((i: any) => i.name === "SNTRLMSF")['value']);

        if (service === '1') {
          setServiceStatus({
            type: 'ADSL',
            service: service === '1',
            port: service === '1' && port === '1',
            speed: speed > 0 ? speed / 1000 : 0,
            distance
          })

          initService = true;
        }
      }

      if (controlService['vdsl'].find !== undefined) {
        const service = controlService['vdsl'].find((i: any) => i.name === "SNTRLHZMT") === undefined ?
          0 : controlService['vdsl'].find((i: any) => i.name === "SNTRLHZMT")['value'];
        const port = controlService['vdsl'].find((i: any) => i.name === "BSPRT") === undefined ?
          0 : controlService['vdsl'].find((i: any) => i.name === "BSPRT")['value']
        const speed = controlService['vdsl'].find((i: any) => i.name === "NMSMAX") === undefined ?
          0 : parseInt(controlService['vdsl'].find((i: any) => i.name === "NMSMAX")['value']);
        const distance = controlService['vdsl'].find((i: any) => i.name === "SNTRLMSF") === undefined ?
          0 : parseInt(controlService['adsl'].find((i: any) => i.name === "SNTRLMSF")['value']);

        if (service === '1') {
          setServiceStatus({
            type: 'VDSL',
            service: service === '1',
            port: service === '1' && port === '1',
            speed: speed > 0 ? speed / 1000 : 0,
            distance
          })

          initService = true;
        }
      }

      if (controlService['fttxv1'].find !== undefined) {
        const service = controlService['fttxv1'].find((i: any) => i.name === "SNTRLHZMT") === undefined ?
          0 : controlService['fttxv1'].find((i: any) => i.name === "SNTRLHZMT")['value'];
        const port = controlService['fttxv1'].find((i: any) => i.name === "BSPRT") === undefined ?
          0 : controlService['fttxv1'].find((i: any) => i.name === "BSPRT")['value']

        let speedStatus = controlService['fttxv1'].find((i: any) => i.name === "FTTX1GB") === undefined ?
          '0' : controlService['fttxv1'].find((i: any) => i.name === "FTTX1GB")['value'];
        let speed: number;
        if (speedStatus === '0' || speedStatus === '1') {
          speed = 1000;
        } else {
          speed = 100;
        }

        const distance = controlService['vdsl'].find((i: any) => i.name === "SNTRLMSF") === undefined ?
          0 : parseInt(controlService['adsl'].find((i: any) => i.name === "SNTRLMSF")['value']);

        if (service === '1') {
          setServiceStatus({
            type: 'Fiber',
            service: service === '1',
            port: service === '1' && port === '1',
            speed: speed,
            distance
          })

          initService = true;
        }
      }

      if (!initService) {
        setServiceStatus({
          type: null,
          service: false,
          port: false,
          speed: 0
        })
      }
    } catch (e: any) {
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: e.response.data.error ?? 'Bir hata oluştu!',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const handleReset = () => {
    setServiceStatus(null);
    setAdres(null);
    setBbkKod(null);
  }

  return (
    <Layout>
      <div className={classes.infrastructure}>
        <h1>PANANET İNTERNET ALTYAPI VE PORT SORGULAMA</h1>
        <p className="headline">
          İnternet altyapısı, port durumu ve adresinize göre alabileceğiniz hızı sorgulamanız için hizmet almak
          istediğiniz adres bilgilerini dikkatli bir şekilde seçerek <strong>Altyapı Sorgula</strong> butonuna basarak
          sorgulayabilirsiniz.
        </p>
        <Flex align="center" justify="start" direction="column">
          {!initialized ? (
            <>
              {initialError ? (
                <Alert color="red">
                  <Flex justify="center" align="center" direction="column" wrap="wrap" gap="md">
                    <Text size="md">Veriler yüklenirken bir hata oluştu!</Text>
                    <Button leftIcon={<IconRefresh size={14}/>}
                            color="green"
                            onClick={getInitialData}>Yeniden dene</Button>
                  </Flex>
                </Alert>
              ) : (
                <Loader variant="bars"/>
              )}
            </>
          ) : (
            <>
              {!serviceStatus ? (
                <form onSubmit={form.onSubmit(handleSubmit)}>
                  <LoadingOverlay visible={loading}/>
                  <Tabs value={queryType} className="tabs">
                    <Tabs.List grow className="tabs-list">
                      <Tabs.Tab value="address"
                                icon={<IconListCheck/>}
                                onClick={() => setQueryType('address')}>Adrese Göre Sorgula</Tabs.Tab>
                      <Tabs.Tab value="code"
                                icon={<IconCodeAsterix/>}
                                onClick={() => setQueryType('code')}>BBK Kodu ile Sorgula</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="address">
                      <Flex align="center" justify="center" direction="column" wrap="wrap" gap="md"
                            className="container">
                        <Select label="Şehir"
                                placeholder="Şehir seçiniz"
                                data={sehirler}
                                withAsterisk
                                searchable
                                clearable
                                size="lg"
                                style={{width: '100%'}}
                                {...form.getInputProps('sehir')}
                                onChange={handleChangeSehir}/>
                        {selectedSehir && ilceler.length > 0 && (
                          <Select label="İlçe"
                                  placeholder="İlçe seçiniz"
                                  data={ilceler}
                                  ref={ilceRef}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('ilce')}
                                  onChange={handleChangeIlce}/>
                        )}
                        {selectedIlce && bucaklar.length > 1 && (
                          <Select label="Bucak"
                                  placeholder="Bucak seçiniz"
                                  ref={bucakRef}
                                  data={bucaklar}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('bucak')}
                                  onChange={handleChangeBucak}/>
                        )}
                        {selectedBucak && koyler.length > 1 && (
                          <Select label="Köy"
                                  placeholder="Köy seçiniz"
                                  ref={koyRef}
                                  data={koyler}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('koy')}
                                  onChange={handleChangeKoy}/>
                        )}
                        {selectedKoy && mahalleler.length > 0 && (
                          <Select label="Mahalle"
                                  placeholder="Mahalle seçiniz"
                                  ref={mahalleRef}
                                  data={mahalleler}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('mahalle')}
                                  onChange={handleChangeMahalle}/>
                        )}
                        {selectedMahalle && sokaklar.length > 0 && (
                          <Select label="Sokak"
                                  placeholder="Sokak seçiniz"
                                  ref={sokakRef}
                                  data={sokaklar}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('sokak')}
                                  onChange={handleChangeSokak}/>
                        )}
                        {selectedSokak && binalar.length > 0 && (
                          <Select label="Apartman/Bina no"
                                  placeholder="Apartman/Bina no seçiniz"
                                  ref={binaRef}
                                  data={binalar}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('bina')}
                                  onChange={handleChangeBina}/>
                        )}
                        {selectedBina && daireler.length > 0 && (
                          <Select label="Daire/Kapı no"
                                  placeholder="Daire/Kapı no seçiniz"
                                  ref={daireRef}
                                  data={daireler}
                                  withAsterisk
                                  searchable
                                  clearable
                                  size="lg"
                                  style={{width: '100%'}}
                                  {...form.getInputProps('daire')}
                                  onChange={handleChangeDaire}/>
                        )}
                        {selectedDaire && (
                          <Button type="submit"
                                  color="green"
                                  leftIcon={<IconSend/>}
                                  size="xl"
                                  fullWidth
                                  ref={submitButtonRef}
                                  loading={loading}
                                  uppercase>Altyapı Sorgula</Button>
                        )}
                      </Flex>
                    </Tabs.Panel>
                    <Tabs.Panel value="code">
                      <Flex align="center" justify="center" direction="column" wrap="wrap" gap="md"
                            className="container">
                        <TextInput label="BBK Kodu"
                                   placeholder="BBK Kodu giriniz"
                                   withAsterisk
                                   size="lg"
                                   icon={<IconCodeAsterix/>}
                                   style={{width: '100%'}}
                                   {...form.getInputProps('bbk')}/>
                        <Button type="submit"
                                color="green"
                                leftIcon={<IconSend/>}
                                size="xl"
                                fullWidth
                                ref={submitButtonRef}
                                loading={loading}
                                uppercase>Altyapı Sorgula</Button>
                      </Flex>
                    </Tabs.Panel>
                  </Tabs>
                </form>
              ) : (
                <Flex align="center"
                      justify="center"
                      direction="column"
                      wrap="wrap"
                      gap="md"
                      className="results">
                  <h1>Altyapı Sorgulama Sonucunuz</h1>
                  <div className="item">
                    <h2>Adres Bağlantı Türü</h2>
                    <Badge variant="filled"
                           color={serviceStatus.service ? 'green' : 'red'}
                           size="xl"
                           radius="sm">{serviceStatus.type ?? 'Altyapı bulunamadı'}</Badge>
                  </div>
                  <div className="item">
                    <h2>Adresinizde Alabileceğiniz Maksimum Hız</h2>
                    <Badge variant="filled"
                           color="cyan"
                           size="xl"
                           radius="sm">{serviceStatus.speed.toFixed().toString()} Mbps</Badge>
                  </div>
                  {serviceStatus.distance && (
                    <div className="item">
                      <h2>Santral/Saha Dolabı Tahmini Mesafe</h2>
                      <Badge variant="filled"
                             color="cyan"
                             size="xl"
                             radius="sm">{serviceStatus.distance.toFixed().toString()} Metre</Badge>
                    </div>
                  )}
                  <div className="item">
                    <h2>Port Durumu</h2>
                    <Badge variant="filled"
                           color={serviceStatus.port ? 'green' : 'red'}
                           size="xl"
                           radius="sm">{serviceStatus.port ? 'Var' : 'Yok'}</Badge>
                  </div>
                  <Divider/>
                  <div className="item">
                    <h2>Adres Bilgileri</h2>
                    <Text><strong>Adres:</strong> {adres}</Text>
                    <Text><strong>BBK:</strong> {bbkKod}</Text>
                  </div>
                  <Divider/>
                  <Flex align="center" justify="center" direction="row" gap="md">
                    <Button color="green"
                            leftIcon={<IconListCheck/>}
                            size="xl"
                            uppercase
                            component={Link}
                            to="/">Başvuru Yap</Button>
                    <Button color="orange"
                            leftIcon={<IconRefresh/>}
                            size="xl"
                            uppercase
                            onClick={handleReset}>Tekrar Sorgula</Button>
                  </Flex>
                  <Divider/>
                  <Alert variant="light" color="gray" icon={<IconInfoCircle/>}>
                    Sorgulama sonucunda verilen bilgiler yaklaşık değerlerdir. Gerçek kullanım esnasında bu kapasiteyi
                    kullanmanız her zaman mümkün olmayabilir. Hızınız şebeke altyapısı, bina/hane içi kablo tesisatınız,
                    bölgenizdeki hatların yoğunluğu, sunucuların veya bilgisayarınızın performansı gibi faktörlere bağlı
                    olarak değişiklik gösterebilmektedir.
                  </Alert>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </div>
    </Layout>
  );
}

export default Infrastructure;
