import {ApplicationForm, Header} from "../components";
import React, {useEffect} from "react";
import useStyles from "../styles";
import {Layout} from "../components/partials";

const Home = () => {
  const {classes} = useStyles();

  useEffect(() => {

  }, []);

  return (
    <Layout>
      <Header title="Abone Başvuru Formu" className={classes.header}/>
      <ApplicationForm/>
    </Layout>
  );
}

export default Home;
