import {Link} from 'react-router-dom';
import {Divider, Flex, Text} from '@mantine/core';
import useAppStore from '../../store';

type PropsType = {
  className?: string;
}

const Footer = ({className}: PropsType) => {
  const dealer = useAppStore(state => state.dealer);

  return (
    <footer className={className}>
      <Divider my="md"/>
      <Flex align="center" justify="space-between" direction="row" wrap="wrap" gap="md">
        <Text color="gray">&copy; 2024. PanaNet.</Text>
        <Flex align="center" justify="flex-end" direction="row" wrap="wrap" gap="md">
          <Text color="gray" component={Link} to="/">Başvuru</Text>
          <Text color="gray" component={Link} to="/altyapi-sorgulama">Altyapı Sorgulama</Text>
          {dealer === null && (
            <Text color="gray" component={Link}  to="/bayi">Bayi Giriş</Text>
          )}
        </Flex>
      </Flex>
    </footer>
  )
}

export default Footer;
